// extracted by mini-css-extract-plugin
export var ArtistDescription = "Daze-module--ArtistDescription--c91Ra";
export var ArtistInfos = "Daze-module--ArtistInfos--9XFkZ";
export var ButtonWrapper = "Daze-module--ButtonWrapper --FVBVF";
export var CardWrapper = "Daze-module--CardWrapper--pRcSx";
export var CarrouselWrapper2 = "Daze-module--CarrouselWrapper2--Woe3T";
export var Citations = "Daze-module--Citations--S+HBX";
export var DescriptionWrapper = "Daze-module--DescriptionWrapper--YpNP9";
export var ImageWrapper = "Daze-module--ImageWrapper--56ES1";
export var LinkWrapper = "Daze-module--LinkWrapper--pGsEx";
export var MobileProtrait = "Daze-module--MobileProtrait--Ff7AV";
export var More = "Daze-module--More--g-VIS";
export var MoreButton = "Daze-module--MoreButton--NSn2J";
export var NameWrapper = "Daze-module--NameWrapper--OB5eA";
export var PdpWrapper = "Daze-module--PdpWrapper--yYVpT";
export var PhotosWrapper = "Daze-module--PhotosWrapper--vW8Xj";
export var ProfilWrapper = "Daze-module--ProfilWrapper--dTMAL";
export var TitleWrapper = "Daze-module--TitleWrapper--6OURk";
export var Wrapper = "Daze-module--Wrapper--BK1Vz";