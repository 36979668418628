import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Daze.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import DazePhoto2 from "../../../../res/Daze/daze copie.jpg";
import DazePhoto5 from "../../../../res/Photos site/Daze/Daze jeune.jpg"
import DazePhoto7 from "../../../../res/Photos site/Daze/daze 84.jpeg"
import DazePhoto9 from "../../../../res/Photos site/Daze/9.png"
import DazePhoto3 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Daze - 18th street - 2020.png"
import DazePhoto4 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Daze - To serve and protect - 1992.png"
import DazePhoto6 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Daze - Untitled - 2014 (2).png"
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/Daze/portrait.jpg'
import PastPresent1 from "../../../../res/Daze/past-present-1.jpg"
import PastPresent2 from "../../../../res/Daze/past-present-2.jpg"
import PastPresent3 from "../../../../res/Daze/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "daze",
  name: "Daze",
  description: "<p>Chris Daze Ellis est, ce que l’on peut appeler, une légende du graffiti. Né en 1962, il a grandi dans le Brooklyn new-yorkais des années 1970. Sa première confrontation avec le graffiti s’est fait en 1975, lorsqu’à treize ans, il découvre dans un garage de métro, l’une des voitures totalement recouverte d’une immense peinture. \n\nBien qu’il ait suivi des études dans la prestigieuse école d’Art et Design de New York, son art se développera réellement dans la rue et sur les wagons de métros. Il expose ainsi pour la première fois ses oeuvres au Mudd Club en avril 1981, aux côtés d’artistes comme Jean-Michel Basquiat, Keith Haring, Zephyr ou encore Lee Quinones. Un an plus tard, il réalise son premier solo show à la célèbre galerie Fashion Moda dans le Bronx. Daze joue sur plusieurs medium et emploie plusieurs techniques, il excelle aussi bien dans le travail à la bombe, que dans le dessin et le collage.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Daze.",
  photos: [
    { src: PastPresent1, name: "Daze" },
    { src: PastPresent2, name: "Daze" },
    { src: PastPresent3, name: "Daze" },
  ],
  citations: [
    "« Voir ça, m’a vraiment inspiré. Quand j’ai vu ce train, je savais que ce n’était pas un acte hâtif. C’était planifié.»",
    "« Le graffiti était une langue que les critiques voulaient apprendre au niveau superficiel ; ils ne voulaient pas la parler couramment »"
  ]
};

const infos2 = {
  photos: [
    // { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
    { src: DazePhoto3, name: "Daze", artist:"Daze\n(Chris Ellis dit) (né en 1962)", title:"138th street, 2020", desc1: "Peinture aérosol sur toile", desc2 :"Spray paint on canvas", desc3: "132 x 132 cm", desc4:" 51 5/8 x 51 5/8 in.", desc7: "- Acquis auprès de l'artiste." },
    { src: DazePhoto6, name: "Daze", artist:"Daze\n(Chris Ellis dit) (né en 1962)", title:"Untitled, 2014", desc1: "Technique mixte sur papier", desc2:"Mixed media on paper", desc3:"51 x 33 cm", desc4: "20 x 13 in.", desc5:"Signé et daté sur le devant", desc6:"Signed and dated on the front", desc7:"- Acquis auprès de l'artiste", desc8:"<h4>- <i>Nos Fantômes</i> - exposition inaugurale, Ghost galerie, Paris, France.\n10 Septembre - 20 Novembre, 2021.\n- <i>Chris \"DAZE\" Ellis, DAZE, 40 ans de création,</i> Marseille, France.\n5 novembre - 5 décembre 2020.</h4>", desc9:"<h4><i>- Chris \" DAZE \" Ellis, DAZE, 40 ans de création</i>, Château de Forbin, Marseille, France, 5 novembre - 5 décembre 2020, reproduit en pleine page couleur, p.10.</h4>" },
    { src: DazePhoto4, name: "Daze", artist:"Daze\n(Chris Ellis dit) (né en 1962)", title:"To Serve And Protect , 1992", desc1:"Bombe aérosol, acrylique et bâton d'huile sur toile", desc2:"Spray paint, acrylic and oil stick on canvas", desc3:"168 x 203 cm", desc4:"66 3/16 x 80 in.", desc5:"Signé, daté et titré au dos", desc6:"Signed and dated on the back", desc7:"- Collection particulière.", desc8:"<h4>-<i> DAZE</i>, exposition rétrospective, Ghost galerie, Marseille, France, 5 Novembre - 5 Décembre 2020 (catalogue)</h4>", desc9:"<h4>-<i> DAZE</i>, exposition rétrospective, Ghost galerie, Marseille, France, 5 Novembre - 5 Décembre 2020, reproduit dans le catalogue p. 63</h4>" },
  ],
};

const exhibitions = [
  {
    date: "Avril 2018",
    title: "Daze",
    link: "daze",
    artist: "",
    place: "2 rue de Belloi - 13006 Marseille",
    description:"Marseille, le 5 novembre 2020. La GHOST galerie et le Château de Forbin, deux lieux d’art contemporain à Marseille - dédiés au graffiti et au Post-graffiti des années 80 - accueilleront du 5 novembre au 5 décembre 2020 la première « double » exposition de l’artiste new-yorkais Chris DAZE Ellis retraçant ses 40 ans de création artistique. Une rétrospective riche de plus de 60 oeuvres qui relate l’évolution de son travail sur l’ensemble de sa carrière.",
    photos_paths: ["path", "path", "path", "path"],
  },
]

const Daze = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>DAZE <h2 style={{paddingLeft: "16px"}}>(Chris Daze Ellis - Américain)</h2></h1>
          <h3 className={NameWrapper} >Né en 1961</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <br />
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
          <a href={"/fr/expositions/" + exhibitions[0].link} className={LinkWrapper}>« 40 ans de création » - du 05 novembre au 05 décembre 2020 - Marseille.</a> 
        <ul className={ArtistInfos}>
                    <br />
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p>- High School of Art and Design à New York, USA.</p>
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Chris Daze Ellis : Give It All You Got, Ppow Gallery, Ny, USA, January 7th - February 12th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Chris Daze Ellis - 40 ans de création, 80s and 90s work, Ghost Galerie, Marseille, France, November 5th - December 5th.</p>
          <p>- Recent Works, Château de Forbin, Marseille, France, August 30th - September 30th.</p>
          <p>- Next Wave, Institut Catholique de Toulouse, Toulouse, France, February 7th - 29th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- New York Perspectives, Joe Gonzo/Chris DAZE Ellis, curated by Mode2, Urban Spree, Berlin, Germany, November 22nd - December 21st.</p>
          <p>- Seasons, Galleria del Palazzo, Florence, Italy, June 13th - October 31st.</p>
          <p>- The Journey, Woodbury House, London, United Kingdom, May 9th - June 13th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Perspectives In GrayScale, Russ Berrie Medical Science Pavilion at Columbia University, New York, USA, April 7th - September 29th.</p>
          <p>- Portals, Galerie Speerstra, Paris, France, March 17th - April 28th.</p>
          <p>- Daily Commute, P.P.O.W Gallery, New York, USA, February 19th - March 17th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Small Painting, Pop Up show, Galerie Brugier-Rigail, Paris, France, April 27th - 29th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- The Asphalt Jungle, Childs Gallery, Boston, USA, November 14th - January 7th.</p>
          <p>- Chris ‘DAZE’ Ellis: The City is My Muse, The Museum City of New York, New York, USA, April 23rd - June 19th.</p>
          <p>- Now and Later, Avant Garden Gallery, Milan, Italy, April 1st - May 19th. DAZE (Chris Ellis) Born in 1961 in New York, United States Living and working in New York, United States</p>
          <p> - Days of Daze, Art Dynasty Gallery, Forte dei Marmi, Italy.</p>
          <p>- Soundtrack, UV Studios, Buenos Aires, Argentina.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- New Works, Galleria del Palazzo, Florence, Italy. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Street Talk, Chris Daze Ellis in Dialogue with the Collection, Addison Gallery of American Art at the Phillips Academy, Andover, USA, May 3 rd - July 31st.</p>
          <p>- Indigo Dreams, TT Underground Gallery, New York, USA, January 9th - 19th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Chris DAZE Ellis, New Prints and Editions, Empire Art Gallery, New York, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- The Grey Scale, Known Gallery, Los Angeles, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Daze, Chang Art Gallery, Beijing, China, November 6th - December 31st.</p>
          <p>- Daze, The Palazzo Casali, Tuscan Sun Festival, Cortona, Italy, July 30th - August 6th.</p>
          <p>- Daze in Singapore, Fortune Cookie Projects, Singapore, March 4th - 21th.</p>
          <p>- Daze Solo Exhibition, Watergate Street Gallery, Chester, UK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Fat Caps and Burnt Racks : Recent works, Andenken Gallery, Denver, USA, June 10th - June 30th.</p>
          <p>- Daze/ Crash, Adhoc Art Gallery, Brooklyn, USA, May 15th - June 14th.</p>
          <p>- While the City Sleeps, Reflections from the 3rd Rail, Gallery Speerstra, Geneva, Switzerland,</p>
          <p>February 10th - March 28th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- South Bronx to Naples, Entropy Art Gallery, Naples, Italy, November.</p>
          <p>- Daze, Galleria del Palazzo, Florence, Italy.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- The Climate Paintings, My Own Gallery, Milan, Italy, June 15th - July 28th.</p>
          <p>- The Climate Paintings, Galleria Del Palazzo, Florence, Italy.</p>
          <p>- The Climate Paintings, Galleria Del Palazzo, Pietrasanta, Italy,</p>
          <p>- New Paintings, Midoma, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Mulheres Perfecto, Galerie Speerstra, Paris, France, June 21st - July 21st.</p>
          <p>- Paint Away the Pain w/Mode2, 1300 Gallery, Cleveland, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Elevation, Galerie Speerstra, Paris, France, March 5th - April 26th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- The Agenda Paintings, Galerie Speerstra, Paris, France, January 17th - February 20th.</p>
          <p></p>
          <p>- The Sketchbook Paintings, Eastwicke Gallery, Chicago, USA.</p>
          <p>- Portraits, Steven Vail Gallery, Des Moines, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Daze & the Kids from the Urban Art Project, Mural, Kreuzlingen, Switzerland.</p>
          <p>- Works on Paper, Prosper Gallery, New York, USA.</p>
          <p>- Elevated Stations and Interiors, Modern Culture at the Gershwin, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- DAZE, Atelier Laderhof, Stockholm, Sweden.</p>
          <p>- The Agenda Paintings and Monochromes, Eastwick Gallery, Chicago, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- WORKS ON PAPER, 1990-99, Masterpiece Gallery, Chicago, USA.</p>
          <p>- DAZE, CRASH, OH THE EXPRESS TRAIN, Feldkirch, Austria; Palais du Lichtenstein, Munich, Germany. (catalogue)</p>
          <p>- DAZE, CRASH, AMERICAN GRAFFITI, Musée d’Art Moderne et d’Art contemporain, Nice, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- The Coney Island Paintings, Gallerie Four, Basel, Switzerland.</p>
          <p>- DAZE/CRASH: Recent Works, Palazzo Enrico Coveri, Florence, Italy.</p>
          <p>- Daze, Ideya, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- DAZE/DZINE, Summit Street Gallery, lowa City, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- DAZE & CRASH, York University Gallery, York College, New York, USA.</p>
          <p>- DAZE & CRASH, Club U.S.A, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- DAZE, Galerie de la Cathedrale, Nice, France.</p>
          <p>- DAZE WORLD, The Time is Always Now Gallery, Hong Kong.</p>
          <p>- Decade of Drawings, Gallery Michel Gillet, Paris, France.</p>
          <p>- Decade of Drawings, Gallery B5, Monte Carlo, Monaco.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- Daze & Crash, Black and White in Color Gallery, Bronx, USA.</p>
          <p>- Daze & Crash, Gallery Structures, Montpellier, France.</p>
          <p>- Daze, Gallery Michel Gillet, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1989</p>
          <p>- Daze, Museum of American Graffiti, New York, USA.</p>
          <p>- Daze, Gallery B5, Monte Carlo, Monaco.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- New Paintings, Alexander Woods Gallery, New York, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p></p>
          <p>- Crowd Control, Nada Gallery, New York, USA.</p>
          <p>- Daze, Pène du Bois Gallery, Monte Carlo, Monaco.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- DAZE, Eastman- Wahmendorf Gallery, New York, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- CRASH & DAZE, Sidney Janis Gallery, New York, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Daze, Pitekin Thropus Gallery, Tokyo, Japan.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- Daze, Hallwalls Contemporary Arts Center, Buffalo, USA.</p>
          <p>- Daze, Fashion MODA, Bronx, USA.</p>
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Go Figure, curated by Todde James, Over the Influence, Hong Kong, January 30th - March 13th.</p>
          <p>- Beyond The Streets On Paper, Southampton Arts Center, NY, USA, July 17th - August 28th.</p>
          <p>- Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France, 10 Septembre - 20 Novembre, 2021.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- The Bronx Does it Better, Wallworks, Bronx, USA, January 1st - February 2nd.</p>
          <p>- #selected 6, Speerstra Gallery, Bursins, Switzerland, January 9th - January 31st.</p>
          <p>- Goldman Global Arts at Wynwood Walls, Miami, USA, permanent exhibition</p>
          <p>- Art Basel on Beach Street, 212 Arts and Wallworks, New York, USA.</p>
          <p>- Fire on Fire : Art, Music/ Street Club Studio, Musée des Beaux-Arts de Nancy, Nancy, France, October 12th, 2019 - January 12th, 2020.</p>
          <p>- Here and Now, POP International Gallery, New York, USA, December 12th, 2019 - January 12th, 2020.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Beyond the Streets, curated by Roger Gastman, Twenty Five Kent, Brooklyn, USA, June 21st - September 29th.</p>
          <p>- Pop Culture : Selections From Frederick R. Weisman Art Foundation, Ronald H. Silverman Fine Arts Gallery, California State University, Los Angeles, USA, June 1st - August 2nd.</p>
          <p>- Legends, Speerstra Gallery, Paris, France, April 13th - May 11th.</p>
          <p>- Ludwig 30, Ludwig Museum, Budapest, Hungary, February 5th - March 31st.</p>
          <p>- Just Folks, 10 Years of 1975 and Wall Therapy, RIT College of Art and Design City Art Space, Rochester, USA, March 1st - 23th.</p>
          <p>- Cash is King, curated by Olly Walker, Saatchi Gallery, London, United Kingdom, August 21st - September 8th.</p>
          <p></p>
          <p> - From New York City to Marseille III, Ghost Galerie, Marseille, France, September 12th - October 12th.</p>
          <p>- Elements of Style, the 80s Graffiti and Hip Hop Scene of New York, created by Carlo McCormick, Seoul Art Center, Seoul, Korea, December 21st, 2018 - March 20th, 2019.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Art is Where the Heart is Vol. II, Galerie Droste, Paris, France, February 15th - February 27th.</p>
          <p>- From NYC to Marseille, Ghost Galerie, Marseille, France, June 20th - July 26th.</p>
          <p>- Beyond the Streets, curated by Roger Gastman, Los Angeles, USA, May 6th - July 6th.</p>
          <p>- Writers : From subways to streets, Arena studio d’arte, Verona, Italy, December 16th, 2017 - February 17th, 2018.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- MA Collection d’Art Urbain, Galerie Brugier-Rigail, Paris, France, May 18th - June 21st.</p>
          <p>- City As Canvas, Indianapolis Museum of Art, Indianapolis, Indiana; Urban Nation Museum of Urban Art, Berlin, Germany.</p>
          <p>- An Incomplete History of Protest : Selections from the Whitney’s Collection 1940 - 2017, Whitney Museum of American Art, New York, USA.</p>
          <p>- Broad Stripes and Bright Star, Ely Center of Contemporary Art, New Haven, Connecticut; Branford House on Yale University Campus, New Haven, Connecticut, USA.</p>
          <p>- Human Kind, Goldman Global Arts, Miami, USA.</p>
          <p>- #Selected 3, Speerstra Gallery, Paris, France, November 10th - December 21st.</p>
          <p>- From the Streets : An Exhibition of Urban Art, ArtsWestchester, Westchester, New York, USA.</p>
          <p>- Urban Painting Around the World, Live painting event, Speerstra Gallery, Port Hercule, Monaco, July 15th - 16th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Off the Wall, World Trade Gallery, New York, USA.</p>
          <p>- Glamorous Graffiti, Nassau County Museum of Art, Long Island, USA.</p>
          <p>- Street Art: Banksy & Co, Palazzo Pepoli, Museo della Storia di Bologna, Bologna, Italy.</p>
          <p>- Daze & Tats Cru, Jardin Rouge, Marrakech, Morocco.</p>
          <p>- Group Show, 212 Gallery, New York, USA.</p>
          <p>- The Originals, The Sanctuary, Goldman Global Arts, Miami, USA.</p>
          <p>- Magic City, Curated by Carlo McCormick and Ethel Seno, Dresden, Germany.</p>
          <p>- Pigments Vertigo: Daze/ Crash/ Pro176, Galerie Cox, Bordeaux, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Old School, Galerie Slika, Lyon, France, October 16th - November 20th.</p>
          <p>- Coney Island: Visions of an American Dreamland, Curated by Robin Jaffee Frank, Brooklyn Museum, Brooklyn, USA.</p>
          <p>- Impact, 1975 Gallery, Rochester, USA.</p>
          <p>- Fashion Moda: 35 Years Later, Wall Works, Bronx, USA.</p>
          <p>- All Types of Characters, Joshua Liner Gallery, New York, USA.</p>
          <p>- Here Lies, 1975 Gallery, Rochester, USA.</p>
          <p>- New York Meets the Dam, The Amsterdam Museum, Amsterdam, The Netherlands.</p>
          <p>- Sideshow, Curated by Lisa Kereszi, The Edgewood Gallery at Yale University of Art, New Haven, USA.</p>
          <p>- Coney Island : Visions of an American Dreamland, curated by Robin Jeffee Frank, The Wadsworth Atheneum, Hartford, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p></p>
          <p>- Depth of Substance, Succulent Studios, Brooklyn, USA.</p>
          <p>- Point.Focus.Click, Wall Work, Bronx, USA.</p>
          <p>- Fisdt Tate, Wall Work, Bronx, USA.</p>
          <p>- Loisaida : New York’s Lower East Side in the 80s, Addison Gallery of American Art at the Phillips Academy, Andover, USA.</p>
          <p>- From Blade and Daze to Lee and Rammellzee : American Graffiti, Museum de Wieger, Deurne, The Netherlands.</p>
          <p>- City as Canvas: New York City Graffiti from the Martin Wong Collection, Museum of the City of New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- 2nd Biennial International Graffiti Fine Art, MUBE, São Paulo, Brazil.</p>
          <p>- Group Show, Galeria Matilha, São Paulo, Brazil.</p>
          <p>- Graffiti : In the Beginning, Space Junk Art Center, Bayonne, France.</p>
          <p>- Write of Passage, Red Bull Space, New York, USA.</p>
         <p>- Graffiti Mash Up, Fay Gold Gallery, Atlanta, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- This Side of Paradise, curated by Empty No More, Andrew Freeman House, Bronx, USA.</p>
          <p>- The Boneyard Project, Return Trip, Pima Air and Space Museum, Tucson, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Paper Trail, curated by Daze, Shadow Space Gallery, Philadelphia, USA.</p>
          <p>- Songs, Fuse Gallery, New York, USA.</p>
          <p>- Hall of Fame, Speerstra Gallery, Switzerland.</p>
          <p>- Art in the Streets, The Geffen Contemporary at MOCA, Los Angeles, USA , April 17 th - August</p>
          <br />
          <p style={{fontWeight: "bold"}}>18th. (catalogue)</p>
          <p>- Martha Cooper : Remix, Carmichael Gallery, Los Angeles, USA.</p>
          <p>- Art, Access and Decay : New York 1875-1983, curated by Lisa Kahane and Peter Frank, Subliminal Projects, Los Angeles, USA.</p>
          <p>- Dear Japin, We Love You, Benefit at Open House Gallery, New York, USA.</p>
          <p>- Group Show, Alba Art Gallery, Bologna, Italy.</p>
          <p>- Graff City, Opera Gallery, Paris, France.</p>
          <p>- Melange of Street Art, Halenbeck Gallery, Nice, France.</p>
          <p>- Graffiti Art, 40 Years of Expressionism, Grimaldi Forum, Monte Carlo, Monaco.</p>
          <p>- Paths of Rhythm, Fuse Gallery, New York, USA.</p>
          <p>- Abstractions, Opera Gallery, New York, USA.</p>
          <p>- Graff in the City, Opera Gallery, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Draw, Museo De La Ciudad De México, Mexico City, Mexico.</p>
          <p>- Public Consumption, The Puffin Room, New York, USA.</p>
          <p>- Down By Law, Eric Firestone Gallery, East Hampton, USA.</p>
          <p>- Né dans la rue: Graffiti, Fondation Cartier pour l’Art Contemporain, Paris, France (catalogue), July 7th, 2009 - January 10th, 2010.</p>
          <p>- Who’s the King?, Galerie Helenbeck, Nicem France, November 4th, 2009 - Janvier 31st, 2010.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Draw, White Walls, San Francisco, USA.</p>
          <p>- Martin Wong’s Downtown Crossings, Asian/Pacific/American Institute at NYU, New York, USA.</p>
          <p>- Le Tag au Grand Palais, Grand Palais, Paris, France.</p>
          <p>- Then and Now : Celebrating the 20th Anniversary of the Center Show, Gay and Lesbian Community Center, New York, USA.</p>
          <p>- Whole in the Wall, Helenbeck Gallery/Splashlight Studios, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Group Show, Recoat Gallery, Glasgow, Scotland.</p>
          <p>- Group Show, Bernarducci Gallery/ Meisel Gallery, New York, USA.</p>
          <p>- Draw, Stolen Space, London, United Kingdom.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Fabulous Disorders, Caixa Cultural, Rio de Janeiro, Brasil ‘70’s Show’, Powerhouse Gallery, Brooklyn, USA.</p>
          <p>- Charity by Numbers, Corey Helford Gallery, Los Angeles, USA.</p>
          <p>- Ballpoint Pen Drawing Show, Cinders Gallery, Brooklyn, USA.</p>
          <p>- ‘Joyride’ 33 West 17 Street, New York, USA.</p>
          <p>- ‘Joyride’ Collette Store, Paris, France.</p>
          <p>- Group Show, Galleria del Palazzo, Florence, Italy.</p>
          <p>- Group Show, ReCoat Gallery, Glasgow, Scotland.</p>
          <p>- Graffiti Stories, Musee Paul Valery, Sete, France.</p>
          <p>- Graffiti Stories, Musee International Des Arts Modeste, Sète, France.</p>
          <p>- Visual Slang, Henry Street Settlement, New York, USA.</p>
          <p>- Draw, Gallery Lombardi, Austin, USA.</p>
          <p>- Group Show, EU Gallery, Miami, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Graffiti, Brooklyn Museum, New York, USA.</p>
          <p>- New York’s Own, Fuse Gallery, New York, USA.</p>
          <p>- Banned From Television 7, Canal Chapter, New York, USA.</p>
          <p>- Draw, Fuse Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Installation Scion Art Tour, Cassius King Gallery, San Diego, USA, traveled to McCaigWelles Gallery, New York, USA.</p>
          <p>- The New York Yankees and the American Dream, Tampa Museum of Art, Tampa, USA.</p>
          <p>- Group Show, Modern Culture, New York, USA.</p>
          <p>- Writers vs. Riders, Upper Playground, New York, USA.</p>
          <p>- ABC no Rio, Benefit Auction, Deitch Gallery, New York, USA.</p>
          <p>- The Munny Show, Drive By Studios, New York, USA.</p>
          <p>- Group Show, Andrea Rosen Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Group Show, Prosper Gallery, Tokyo, Japan.</p>
          <p>- Tag the System, The Showroom, New York, USA.</p>
          <p>- War and Peace, Woodstock Gallery of Art, Woodstock, USA.</p>
          <p>- The subway Series, Bronx Museum of the Arts, Bronx, USA.</p>
          <p>- East Village ASU, B-side Gallery, New York, USA.</p>
          <p>- East Village USA, The New Museum of Contemporary Art, New York, USA.</p>
          <p>- Kosmopolite, Taxie Gallery, Paris, France.</p>
          <p></p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Graffiti in Print, Prosper Gallery, New York, USA.</p>
          <p>- Daze, Done, Crash, Midoma Gallery, New York, USA.</p>
          <p>- On Line, Feigen Contemporary, New York, USA.</p>
          <p>- Howl! Opening Night Artists Ball, Angel Orensanz Center, New York, USA.</p>
          <p>- NY Group Show, Gallery Speerstra, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- This is the Modern World, Modern Culture, New York, USA.</p>
          <p>- Crash, Daze, Sharp, New Paintings, Pace University Galler, New York, USA.</p>
          <p>- Safe Harbors, Auction to Benefit the Point Community Development Corporation, Pacifico Fine Arts, New York, USA.</p>
          <p>- Aerosol Art, No Name Gallery, Minneapolis, USA.</p>
          <p>- I Love New York Benefit for the World Trade Center, Modern Culture New York, USA.</p>
          <p>- The Armory Show 2001, Pier 90, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Zurich Art Fair, Projects United, Zurich, Switzerland.</p>
          <p>- Group show, NY Gallery, Scottsdale, USA.</p>
          <p>- Toronto International Art Fair, Andrew Edlin Fine Arts, Toronto, Canada.</p>
          <p>- Art Miami, Andrew Edlin Fine Arts, Miami, USA.</p>
          <p>- Tectonic, La Panaderia, Mexico City, Mexico.</p>
          <p>- Landscapes, Scholoss Brunegg, Projects United Kreuzlingen, Switzerland.</p>
          <p>- Milano Contemporary Art Fair, Projects United, Milan, Italy.</p>
          <p>- Summer Show, DFN Gallery, New York, USA.</p>
          <p>- The Base and the beautiful, Andrew Edlin Fine Arts, Hoboken, N.J, Frederick Weisman Collection, Pepperdine University Museum, California, USA.</p>
          <p>- Coming from the Underground, Steven Vail Gallery, Des Moines, USA.</p>
          <p>- Graffiti at Guernsey’s, Puck Building, New York, USA.</p>
          <p id="end">- Gallery Blu, Pontiac, USA.</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Daze;